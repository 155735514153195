import './App.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';

import logo from './resources/logo.svg';
import slider_nav_bottom from './resources/slider_nav_bottom.svg';
import scroll_down from './resources/scroll_down.svg';
import scroll_down_en from './resources/scroll_down_en.svg';
import people from './resources/people.svg';

import dataCenter from './resources/data-center.svg';
import server from './resources/server.svg';
import gdpr from './resources/gdpr.svg';
import bigData from './resources/big-data.svg';
import calculator from './resources/calculator.svg';
import folderManagement from './resources/folder-management.svg';
import chat from './resources/chat.svg';
import install from './resources/install.svg';

import fujitsu from './resources/fujitsu.svg';
import quantum from './resources/quantum.svg';
import commvault from './resources/commvault.svg';
import oracle from './resources/oracle.svg';
import aws from './resources/aws.png';
import juniper from './resources/juniper.svg';
import checkpoint from './resources/checkpoint.png';
import f5 from './resources/f5.svg';
import purestorage from './resources/purestorage.png';
import dell from './resources/dell.svg';
import cloudera from './resources/cloudera.png';
import vmware from './resources/vmware.svg';
import bull from './resources/bull.svg';
//import cyberark from './resources/cyberark.svg';
import proxmox from './resources/proxmox.svg';
import zimbra from './resources/zimbra.png';
import nutanix from './resources/nutanix.svg';

import progressLine from './resources/progress_line.svg';
import checkedSymbol from './resources/checked-symbol.svg';

import email from './resources/email.svg';
import phone from './resources/phone-call.svg';
import place from './resources/place.svg';

function getLanguageString(locale, lt, en) {
	if (locale) {
		return lt;
	} else {
		return en;
	}
}

function DesktopApp() {
	const [ localeLt, setLocaleLt ] = useState(true);
	return (
		<Box>
			<Grid id="header">
				<Grid id="navBar" container>
					<Grid item xs={2}>
						<Paper elevation={0}>
							<img id="logo" src={logo} />
						</Paper>
					</Grid>
					<Grid item xs={10}>
						<Paper elevation={0}>
							<a
								id="lang"
								onClick={() => {
									setLocaleLt(!localeLt);
								}}
							>
								{getLanguageString(localeLt, 'EN', 'LT')}
							</a>
							<a href="#contacts">{getLanguageString(localeLt, 'Kontaktai', 'Contacts')}</a>
							<a href="#trusted">{getLanguageString(localeLt, 'Mumis pasitiki', 'We are trusted')}</a>
							<a href="#partners">{getLanguageString(localeLt, 'Partnerystės', 'Partners')}</a>
							<a href="#services">
								{getLanguageString(localeLt, 'Paslaugos ir sprendimai', 'Services and solutions')}
							</a>
							<a href="#aboutUs">{getLanguageString(localeLt, 'Apie mus', 'About us')}</a>
						</Paper>
					</Grid>
				</Grid>

				<Grid id="welcome" container>
					<Grid item xs={12}>
						<Paper elevation={0}>
							<h1 className="white">
								{getLanguageString(
									localeLt,
									<div>
										Duomenų valdymo<br />ekspertai
									</div>,
									<div>
										Data Management<br />Experts
									</div>
								)}
							</h1>
							<p className="white">
								{getLanguageString(
									localeLt,
									<div>
										Esame kompetetingi, patyrę, lankstūs ir charizmatiški,<br />
										teikiame aukščiausios kokybės sprendimus
									</div>,
									<div>
										Experienced, competent and enthusiastic Team of specialists<br />
										providing the best in class quality solutions
									</div>
								)}
							</p>
							<div>
								<img id="sliders" src={slider_nav_bottom} />
							</div>
							<div>
								<a href="#services">
									{getLanguageString(
										localeLt,
										<img id="more" src={scroll_down} />,
										<img id="more" src={scroll_down_en} />
									)}
								</a>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</Grid>

			<Grid id="aboutUs" container>
				<Grid item xs={6.5}>
					<Paper elevation={0}>
						<img id="people" src={people} />
					</Paper>
				</Grid>
				<Grid item xs={5.5}>
					<Paper elevation={0}>
						<h1 className="black">{getLanguageString(localeLt, 'Apie mus', 'About us')}</h1>
						<p className="black">
							{getLanguageString(
								localeLt,
								<div>
									Šiandienos technologijų pasaulyje mes padedame kurti ateitį.<br />
									<br />
									Neleidžiame švaistyti brangaus laiko veltui, versdami sudėtingas užduotis
									paprastomis.<br />
									<br />
									Mes nepamirštame, kas yra mūsų klientai.<br />
									<br />
									Jaučiamės laimingi priimdami iššūkius - taip mes kartu tobulėjame ir augame!<br />
								</div>,
								<div>
									In today's technology world we help shape the future.<br />
									<br />
									We save your time - make complex tasks simple.<br />
									<br />
									We do not forget how important are our customers.<br />
									<br />
									We accept challenges - this makes us improve and grow.<br />
								</div>
							)}
						</p>
						<a href="#contactForm">
							<button id="myButton" className="butn">
								{getLanguageString(localeLt, 'SUSISIEKITE SU MUMIS', 'CONTACT US')}
							</button>
						</a>
					</Paper>
				</Grid>
			</Grid>

			<Grid id="howCanWeHelp" container item>
				<Grid item xs={12}>
					<Paper elevation={0}>
						<h1 className="white">
							{getLanguageString(localeLt, 'Kuo galime jums padėti?', 'How can we help you?')}
						</h1>
						<p className="white">
							{getLanguageString(
								localeLt,
								<div>
									Kompetetinga, patyrusi, lanksti ir charizmatiška komanda tiekianti<br />aukščiausios
									kokybės sprendimus
								</div>,
								<div>
									A competent, experienced and enthusiastic team supplying<br />the most innovative
									quality solution
								</div>
							)}
						</p>
						<h1 id="services" className="black">
							{getLanguageString(localeLt, 'Mūsų paslaugos ir sprendimai', 'Our services and solutions')}
						</h1>
						<p className="black">
							{getLanguageString(
								localeLt,
								'Savo žiniomis ir patirtimi mielai pasidalinsime su Jumis!',
								'Let us share our knowledge and experience with You!'
							)}
						</p>
						<Grid id="servicesDiv" container item spacing={3}>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="data-center" src={dataCenter} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												Duomenų centrų<br />architektūra ir dizainas
											</div>,
											<div>
												Data center design and<br />architecture
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="server" src={server} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												IT infrastruktūros<br />konsolidavimas ir Debesų<br />kompiuterija
											</div>,
											<div>
												Consolidation of IT<br />Infrastructure and Cloud<br />computing
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="gdpr" src={gdpr} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												IT sauga ir asmens duomenų<br />apsauga (GDPR)
											</div>,
											<div>
												IT Security and personal data<br />protection (GDPR)
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="bigData" src={bigData} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												Didieji duomenys (Big Data) ir<br />Daiktų internetas (IoT)
											</div>,
											<div>
												Big data and the Internet of<br />Things (IoT)
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="calculator" src={calculator} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												Aukšto našumo skaičiavimo<br />sistemos (HPC)
											</div>,
											<div>
												High-performance computing<br />Systems (HPC)
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="folderManagement" src={folderManagement} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												Duomenų valdymo ir<br />archyvavimo sprendimai
											</div>,
											<div>
												Data management and<br />archiving solutions
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="chat" src={chat} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												IT Seminarai, mokymai ir<br />konsultavimo paslaugos
											</div>,
											<div>
												IT seminars, training and<br />consultancy services
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
							<Grid item xs={3}>
								<Paper elevation={12}>
									<br />
									<img id="install" src={install} />
									<br />
									<br />
									<p>
										{getLanguageString(
											localeLt,
											<div>
												Techninės ir programinės įrangos<br />diegimas, migravimas, remontas
											</div>,
											<div>
												Hardware and software<br />installation, migrations, repairs
											</div>
										)}
									</p>
									<br />
								</Paper>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Grid id="learn" container>
				<Grid item xs={12}>
					<Paper elevation={0}>
						<h1 className="white">
							{getLanguageString(
								localeLt,
								<div>
									Nuolat mokomės<br />ir tobulėjame
								</div>,
								<div>
									We are constantly<br />learning and developing
								</div>
							)}
						</h1>
						<p className="white">
							{getLanguageString(
								localeLt,
								'Savo žiniomis ir patirtimi mielai pasidalinsime su Jumis!',
								'Let us share our knowledge and experience with You!'
							)}
						</p>
						<div id="buttonDiv">
							<a href="#contactForm">
								<button id="myButton2" className="butn">
									{getLanguageString(localeLt, 'SUSISIEKITE SU MUMIS', 'CONTACT US')}
								</button>
							</a>
						</div>
					</Paper>
				</Grid>
			</Grid>

			<Grid id="partners" container>
				<Grid item xs={12}>
					<Paper elevation={0}>
						<h1 className="black">
							{getLanguageString(
								localeLt,
								'Partnerystės ir kompetencijos',
								'Partnerships and competencies'
							)}
						</h1>
						<Grid id="partnersDiv" container item spacing={3}>
							<Grid item xs={3}>
								<a href="https://www.fujitsu.com/global/">
									<Paper>
										<img id="fujitsu" src={fujitsu} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.quantum.com/en/">
									<Paper>
										<img id="quantum" src={quantum} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.commvault.com/">
									<Paper>
										<img id="commvault" src={commvault} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.nutanix.com/">
									<Paper>
										<img id="nutanix" src={nutanix} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.cloudera.com/">
									<Paper>
										<img id="cloudera" style={{ width: '80%' }} src={cloudera} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.purestorage.com/">
									<Paper>
										<img id="purestorage" style={{ width: '60%' }} src={purestorage} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.checkpoint.com/">
									<Paper>
										<img id="checkpoint" style={{ width: '15em' }} src={checkpoint} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.f5.com/">
									<Paper>
										<img id="f5" src={f5} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.juniper.net/us/en.html">
									<Paper>
										<img id="juniper" src={juniper} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.dell.com/en-lt/lp">
									<Paper>
										<img id="dell" src={dell} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://aws.amazon.com/">
									<Paper>
										<img id="aws" style={{ width: '45%' }} src={aws} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.vmware.com/">
									<Paper>
										<img id="vmware" src={vmware} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://atos.net/en/solutions/data-analytics-business-computing">
									<Paper>
										<img id="bull" src={bull} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.proxmox.com/">
									<Paper>
										<img id="proxmox" style={{ width: '35%' }} src={proxmox} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.zimbra.com/">
									<Paper>
										<img id="zimbra" style={{ width: '90%' }} src={zimbra} />
									</Paper>
								</a>
							</Grid>
							<Grid item xs={3}>
								<a href="https://www.oracle.com/">
									<Paper>
										<img id="oracle" style={{ width: '90%' }} src={oracle} />
									</Paper>
								</a>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Grid id="trusted" container>
				<Grid item xs={6}>
					<Paper elevation={0} />
				</Grid>
				<Grid item xs={6}>
					<Paper elevation={0}>
						<h1 className="white">
							{getLanguageString(
								localeLt,
								<div>
									Mumis pasitiki<br />šių sektorių lyderiai
								</div>,
								<div>
									Leaders in industries<br />that trust us
								</div>
							)}
						</h1>
						<img id="progressLine" src={progressLine} />
						<Grid id="trustedList" container>
							<Grid item xs={4}>
								<div>
									<img src={checkedSymbol} />
									<p className="white">
										{getLanguageString(localeLt, 'Pramonė ir gamyba', 'Retail and manufacturing')}
									</p>
								</div>
								<div>
									<img src={checkedSymbol} />
									<p className="white">{getLanguageString(localeLt, 'Švietimas', 'Education')}</p>
								</div>
								<div>
									<img src={checkedSymbol} />
									<p className="white">
										{getLanguageString(localeLt, 'Sveikatos priežiūra', 'Health case')}
									</p>
								</div>
							</Grid>
							<Grid item xs={4}>
								<div>
									<img src={checkedSymbol} />
									<p className="white">{getLanguageString(localeLt, 'Žiniasklaida', 'Media')}</p>
								</div>
								<div>
									<img src={checkedSymbol} />
									<p className="white">
										{getLanguageString(localeLt, 'Nekilnojamasis turtas', 'Real estate')}
									</p>
								</div>
								<div>
									<img src={checkedSymbol} />
									<p className="white">
										{getLanguageString(localeLt, 'Viešasis sektorius', 'Public sector')}
									</p>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Grid id="contactForm" container>
				<Grid item xs={12}>
					<Paper elevation={0}>
						<p>{getLanguageString(localeLt, 'Susisiekite', 'Contact us')}</p>
						<div className="container">
							<form action="/ContactForm.php" method="post">
								<div className="column">
									<label htmlFor="fname" />
									<input
										type="text"
										id="fname"
										name="name"
										placeholder={getLanguageString(localeLt, 'Vardas', 'First Name')}
										required
									/>

									<label htmlFor="lname" />
									<input
										type="text"
										id="lname"
										name="lastname"
										placeholder={getLanguageString(localeLt, 'Pavardė', 'Last Name')}
										required
									/>
									<label htmlFor="phone" />
									<input
										type="tel"
										id="phone"
										name="phone"
										placeholder={getLanguageString(localeLt, 'Telefono numeris', 'Phone Number')}
									/>

									<label htmlFor="email" />
									<input
										type="email"
										id="email"
										name="email"
										placeholder={getLanguageString(localeLt, 'El. paštas', 'Email address')}
										required
									/>
								</div>

								<div className="row">
									<label htmlFor="message" />
									<textarea
										id="message"
										name="message"
										placeholder={getLanguageString(localeLt, 'Žinutė', 'Message')}
										style={{ height: '150px' }}
										required
									/>
								</div>
								<div className="rowLast">
									<input
										type="submit"
										id="submit"
										value={getLanguageString(localeLt, 'SIŲSTI ŽINUTĘ', 'SEND MESSAGE')}
									/>
									<div
										className="g-recaptcha"
										data-sitekey="6Lfhr7EUAAAAAA3SgM0ZX9rtkySQgHMTWB1Nm5o8"
									/>
								</div>
							</form>
						</div>
					</Paper>
				</Grid>
			</Grid>

			<Grid id="contacts" container>
				<Grid item xs={2.4} />
				<Grid item xs={2.4}>
					<Paper elevation={0}>
						<img src={email} />
						<h1 className="white">info@duvait.lt</h1>
						<p className="white">{getLanguageString(localeLt, 'El.paštas', 'Email')}</p>
					</Paper>
				</Grid>
				<Grid item xs={2.4}>
					<Paper elevation={0}>
						<img src={phone} />
						<h1 className="white">+370 617 55137</h1>
						<p className="white">{getLanguageString(localeLt, 'Telefonas', 'Phone')}</p>
					</Paper>
				</Grid>
				<Grid item xs={2.4}>
					<Paper elevation={0}>
						<img src={place} />
						<h1 className="white">Maironio g. 23-7, Vilnius</h1>
						<p className="white">{getLanguageString(localeLt, 'Adresas', 'Address')}</p>
					</Paper>
				</Grid>
				<Grid item xs={2.4} />
			</Grid>

			<Grid id="map" container>
				<Grid item xs={12}>
					<Paper elevation={0}>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2306.738278738117!2d25.292476599999997!3d54.6790348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd943e40162f41%3A0xfff2ab923bf7e39e!2sMaironio%20g.%2023%2C%20Vilnius%2001125!5e0!3m2!1sen!2slt!4v1658684175354!5m2!1sen!2slt"
							width="100%"
							height="450"
							style={{ border: '0' }}
							allowFullScreen
						/>
					</Paper>
				</Grid>
			</Grid>

			<Grid id="footer" container>
				<Grid item xs={2.4}>
					<Paper elevation={0} />
				</Grid>
				<Grid item xs={2}>
					<Paper elevation={0}>
						<img id="logo" src={logo} alt="logo" />
						<p className="white">
							{getLanguageString(
								localeLt,
								'Profesionali, sertifikuotų IT ekspertų komanda',
								'Professional, certified team of IT experts'
							)}
						</p>
					</Paper>
				</Grid>
				<Grid item xs={1.6}>
					<Paper elevation={0} />
				</Grid>
				<Grid item xs={1.5}>
					<Paper elevation={0}>
						<h1 className="white">{getLanguageString(localeLt, 'Meniu', 'Meniu')}</h1>
						<a href="#aboutUs">
							<p className="blue">{getLanguageString(localeLt, 'Apie mus', 'About us')}</p>
						</a>
						<a href="#services">
							<p className="blue">{getLanguageString(localeLt, 'Paslaugos ir sprendimai', 'Services')}</p>
						</a>
						<a href="#partners">
							<p className="blue">{getLanguageString(localeLt, 'Partnerystės', 'Partners')}</p>
						</a>
						<a href="#trusted">
							<p className="blue">{getLanguageString(localeLt, 'Mumis pasitiki', 'We are trusted')}</p>
						</a>
						<a href="#contacts">
							<p className="blue">{getLanguageString(localeLt, 'Kontaktai', 'Contacts')}</p>
						</a>
					</Paper>
				</Grid>
				<Grid item xs={1.5}>
					<Paper elevation={0}>
						<h1 className="white">{getLanguageString(localeLt, 'Sritys', 'Fields')}</h1>
						<p className="blue">
							{getLanguageString(localeLt, 'Pramonė ir gamyba', 'Retail and manufacturing')}
						</p>
						<p className="blue">{getLanguageString(localeLt, 'Švietimas', 'Education')}</p>
						<p className="blue">{getLanguageString(localeLt, 'Sveikatos priežiūra', 'Health case')}</p>
						<p className="blue">{getLanguageString(localeLt, 'Žiniasklaida', 'Media')}</p>
						<p className="blue">{getLanguageString(localeLt, 'Nekilnojamasis turtas', 'Real esstate')}</p>
						<p className="blue">{getLanguageString(localeLt, 'Viešasis sektorius', 'Public sector')}</p>
					</Paper>
				</Grid>
				<Grid item xs={1.5}>
					<Paper elevation={0}>
						<h1 className="white">{getLanguageString(localeLt, 'Rekvizitai', 'Requisites')}</h1>
						<p className="blue">
							{getLanguageString(localeLt, 'Įm. kodas - 304096321', 'Comp. code - 304096321')}
						</p>
						<p className="blue">
							{getLanguageString(localeLt, 'PVM kodas - LT100009810212', 'VAT code - LT100009810212')}
						</p>
						<p className="blue">
							{getLanguageString(
								localeLt,
								'Adresas - Maironio g. 23-7, Vilnius',
								'Address - Maironio g. 23-7, Vilnius'
							)}
						</p>
					</Paper>
				</Grid>

				<Grid item xs={2.4} />
				<Grid item xs={6}>
					<Paper elevation={0}>
						<h1 className="meta">
							{getLanguageString(localeLt, 'Dizainas sukurtas ALIUS.LT', 'Design by ALIUS.LT')}
						</h1>
					</Paper>
				</Grid>
				<Grid item xs={3.6}>
					<Paper elevation={0}>
						<h1 className="meta">
							{getLanguageString(
								localeLt,
								'Visos teisės saugomos 2024 Duvait UAB',
								'All rights reserved by 2024 Duvait JSC'
							)}
						</h1>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
}

export default DesktopApp;
